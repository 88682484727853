.container {
    padding: 10px;
    width: 400px;
    margin: 0 auto;
    border: 1px solid rgba($color: gray, $alpha: 1);
    border-radius: 5px;
    margin-top: 50px;
}
.register {
    text-decoration: none;
    color: rgba($color: blue, $alpha: 0.8);
    &:hover {
        text-decoration: underline;
    }
}
