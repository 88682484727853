.body_shareContact {
    width: 600px;
    height: auto;
    .search_Contact {
        width: 100%;
        margin-bottom: 15px;
        .input_shareContact {
            margin: 0 auto;
            width: 90%;
        }
    }
    .list_contacts {
        width: 100%;
        height: 280px;
        border-top: 1px solid rgba($color: gray, $alpha: 0.5);
        display: flex;
        justify-content: space-around;
        .contacts {
            width: 50%;
            height: auto;
            margin-top: 10px;
            border-radius: 5px;
            overflow-y: auto;
            overflow-x: hidden;
            .contact {
                width: 100%;
                display: flex;
                align-items: center;
                padding: 5px;
                border-radius: 5px;
                cursor: pointer;
                &:hover {
                    background-color: rgba($color: gray, $alpha: 0.5);
                }
                .contact_checkIcon {
                    height: 20px;
                    width: 20px;
                    border: 1px solid rgba($color: gray, $alpha: 0.5);
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-right: 10px;
                    color: white;
                }
                .contact_checkIcon_active {
                    background-color: blue;
                }
                .contact_avatar {
                    height: 100%;
                    margin-right: 10px;
                }
                .contact_name {
                    height: 100%;
                    width: 70%;
                    font-weight: bold;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
        .contacts_add {
            width: 40%;
            height: auto;
            margin-top: 10px;
            border: 1px solid rgba($color: gray, $alpha: 0.5);
            border-radius: 5px;
            padding: 5px 5px 0 5px;
            overflow-y: auto;
            overflow-x: hidden;
            .contact_added {
                width: 95%;
                padding: 5px;
                display: flex;
                align-items: center;
                border-radius: 5px;
                cursor: pointer;
                background-color: #e5efff;
                margin: 5px 0;
                .contact_added_avatar {
                    margin-right: 10px;
                }
                .contact_added_name {
                    font-weight: bold;
                    width: 70%;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                .contact_added_iconCheck {
                    height: 20px;
                    width: 20px;
                    color: white;
                    border: 1px solid rgba($color: gray, $alpha: 0.5);
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: blue;
                }
            }
        }
    }
}
