.content-preview {
    width: 300px;
    height: auto;
    .content-preview-image {
        width: auto;
        max-width: 100%;
        height: auto;
        max-height: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            width: auto;
            max-width: 100%;
            height: auto;
            max-height: 200px;
            object-fit: cover;
        }
    }
    .content-preview-text {
        margin-top: 15px;
        width: 100%;
        height: 50px;
    }
}
