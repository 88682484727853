.body_formForwardMessage {
    width: 400px;
    height: auto;
    .search_Contact {
        width: 100%;
        margin-bottom: 15px;
        .input_shareContact {
            margin: 0 auto;
            width: 90%;
        }
    }
    .list_chats {
        width: 100%;
        height: 280px;
        border-top: 1px solid rgba($color: gray, $alpha: 0.5);
        overflow-y: auto;
        .list_chats_title {
            margin-left: 10px;
            width: 80%;
            font-weight: 500;
        }
        .chat {
            width: 100%;
            display: flex;
            align-items: center;
            padding: 5px 0;
            cursor: pointer;
            &:hover {
                background-color: rgba($color: gray, $alpha: 0.5);
            }
            .chat_checkIcon {
                height: 20px;
                width: 20px;
                border: 1px solid rgba($color: gray, $alpha: 0.5);
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: 10px;
                margin-right: 10px;
                color: white;
            }
            .chat_checkIcon_active {
                background-color: blue;
            }
            .chat_avatar {
                height: 100%;
                margin-right: 10px;
            }
            .chat_name {
                height: 100%;
                width: 70%;
                font-weight: bold;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
}
