.content-preview-files {
    width: 300px;
    height: auto;
    .content-preview-file {
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        .content-preview-file-icon {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background-color: aquamarine;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .content-preview-file-right {
            margin-left: 5px;
            width: 85%;
            height: 100%;
            .content-preview-file-right-name {
                font-size: 14px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-weight: bold;
            }
            .content-preview-file-right-size {
                margin-top: 5px;
                font-size: 12px;
                color: rgba($color: gray, $alpha: 0.5);
            }
        }
    }
}
