.right {
    width: 70%;
    height: 100%;
    .header {
        width: 100%;
        height: 10%;
        border-bottom: 1px solid rgba($color: gray, $alpha: 0.5);
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        .header_information {
            height: 100%;
            display: flex;
            align-items: center;
            .avatar {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: gray;
                margin-left: 15px;
                cursor: pointer;
                i {
                    color: white;
                }
            }
            .name_member {
                margin-left: 15px;
                display: flex;
                align-items: flex-start;
                flex-direction: column;
                .name {
                    font-weight: bold;
                    cursor: pointer;
                    font-size: 20px;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
                .member {
                    cursor: pointer;
                    color: gray;
                    i {
                        margin-right: 5px;
                    }
                }
                .online {
                    font-weight: bold;
                    color: green;
                }
            }
        }
        .header_feature {
            width: auto;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            i {
                margin-right: 20px;
                padding: 5px;
                border-radius: 5px;
                cursor: pointer;
                &:hover {
                    background-color: #caced4;
                }
            }
        }
    }
    .message-pin {
        width: 100%;
        height: 10%;
        background-color: white;
        text-align: left;
        font-size: 14px;
        z-index: 9;
        display: none;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        border-bottom: 1px solid rgba($color: gray, $alpha: 0.5);
        .message-pin-title {
            padding: 0 10px;
            font-weight: 500;
        }
        .message-pin-content {
            padding: 0 10px;
            width: 95%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            z-index: 999999;
            .content-pinned-message {
                display: flex;
                width: auto;
                align-items: center;
                cursor: pointer;
                .sender-pinned-message {
                    margin-right: 5px;
                }
                .pinned-message-image {
                    display: flex;
                    align-items: center;
                    .pinned-message-img {
                        height: 20px;
                        width: 20px;
                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .pinned-message-img-title {
                        margin-left: 10px;
                    }
                }
                .pinned-message-file {
                    display: flex;
                    align-items: center;
                    i {
                        margin-right: 10px;
                    }
                }
            }
        }
    }
    .message-pin-active {
        display: block;
    }
    .body {
        width: 100%;
        height: 72%;
        background-color: #d8e7f1;
        display: flex;
        flex-direction: column-reverse;
        text-align: left;
        overflow-y: auto;
        overflow-x: hidden;
        border-bottom: 1px solid rgba($color: gray, $alpha: 0.5);
        position: relative;
        .loading {
            position: fixed;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
            width: 70%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .reachMax {
            width: 100%;
            text-align: center;
            opacity: 0.5;
        }
    }
    .body::-webkit-scrollbar {
        width: 10px;
    }
    .body::-webkit-scrollbar-thumb {
        background-color: #9ab0c0;
        border-radius: 25px;
    }
    .body-active {
        height: 62%;
    }
    .footer {
        width: 100%;
        height: 17%;
        position: relative;
        .reply {
            position: absolute;
            top: -50%;
            left: 0;
            background-color: white;
            border-bottom: 1px solid rgba($color: black, $alpha: 0.5);
            width: 100%;
            height: 50%;
            display: none;
            .reply-container {
                height: 80%;
                width: 95%;
                border-radius: 5px;
                background-color: #f4f5f7;
                text-align: left;
                padding-left: 10px;
                font-size: 14px;
                position: relative;
                .reply-content {
                    width: 100%;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    .reply-message-image {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        .reply-message-img {
                            width: 20px;
                            height: 20px;
                            margin-right: 10px;
                            img {
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }
                }
                i {
                    cursor: pointer;
                    position: absolute;
                    top: 0;
                    right: 0;
                }
            }
        }
        .reply-active {
            display: inline-flex;
            align-items: center;
            justify-content: center;
        }
        .icon {
            display: flex;
            align-items: center;
            width: 100%;
            height: 40%;
            border-bottom: 1px solid rgba($color: gray, $alpha: 0.2);
            i {
                font-size: 20px;
                color: rgba($color: black, $alpha: 0.7);
                margin: 5px 10px 5px 5px;
                padding: 8px;
                border-radius: 5px;
                position: relative;
                cursor: pointer;
                &:hover {
                    background-color: #caced4;
                }
                input {
                    position: absolute;
                    top: 0;
                    right: 0;
                    height: 100%;
                    width: 100%;
                    font-size: 1px;
                    text-align: right;
                    opacity: 0;
                    outline: none;
                    cursor: inherit;
                    display: block;
                }
            }
        }
        .chat_input {
            width: 100%;
            display: flex;
            align-items: center;
            height: 60%;
            input {
                width: 90%;
                height: 100%;
                font-size: 17px;
                border: none;
                outline: none;
                padding-left: 10px;
            }
            i {
                padding: 5px;
                margin-right: 10px;
                border-radius: 5px;
                cursor: pointer;
                &:hover {
                    background-color: #caced4;
                }
            }
        }
        .edit {
            position: absolute;
            top: -50%;
            left: 0;
            background-color: white;
            border-bottom: 1px solid rgba($color: black, $alpha: 0.5);
            width: 100%;
            height: 50%;
            display: none;
            .edit-container {
                height: 80%;
                width: 95%;
                border-radius: 5px;
                background-color: #f4f5f7;
                text-align: left;
                padding-left: 10px;
                font-size: 14px;
                position: relative;
                .edit-name {
                    color: #168ad6;
                    font-weight: 500;
                }
                .edit-content {
                    width: 100%;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                i {
                    cursor: pointer;
                    position: absolute;
                    top: 0;
                    right: 0;
                }
            }
        }
        .edit-active {
            display: inline-flex;
            align-items: center;
            justify-content: center;
        }
    }
}

.boxchat_information {
    width: 0%;
    height: 100%;
    transition: all 0.3s;
    border-left: 1px solid rgba(gray, 0.3);
    overflow: auto;
    z-index: 10;
    &.openFormBoxChatInfo {
        width: 30%;
    }
    .boxchat_information_title {
        width: 100%;
        height: 10%;
        border-bottom: 1px solid rgba($color: gray, $alpha: 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        font-weight: 500;
        position: relative;
        i {
            display: none;
            cursor: pointer;
            position: absolute;
            top: 3px;
            right: 5px;
        }
    }
    .boxchat_information_name_avatar {
        width: 100%;
        height: 18%;
        border-bottom: 1px solid rgba($color: gray, $alpha: 0.5);
        .boxchat_information_name {
            white-space: nowrap;
            text-overflow: ellipsis;
            margin-top: 10px;
            font-weight: bold;
        }
    }
    .boxchat_information_members {
        width: 100%;
        max-height: 300px;
        border-bottom: 1px solid rgba($color: gray, $alpha: 0.5);
        .boxchat_information_members_title {
            width: 100%;
            height: 10%;
            display: flex;
            justify-content: center;
            i {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: 5px;
                color: gray;
            }
            p {
                margin-left: 15px;
                color: black;
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: bold;
            }
        }
        .boxchat_information_members_list {
            width: 100%;
            max-height: 250px;
            overflow: auto;
            .boxchat_information_members_member {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                cursor: pointer;
                &:hover {
                    background-color: #dee1e4;
                    .boxchat_information_members_member_dropdown {
                        opacity: 1;
                    }
                }
                .boxchat_information_members_member_name_position {
                    width: 80%;
                    height: 100%;
                    text-align: left;
                    .boxchat_information_members_member_name {
                        margin-left: 10px;
                    }
                    .boxchat_information_members_member_position {
                        margin-left: 10px;
                        color: rgba($color: black, $alpha: 0.5);
                        font-size: 14px;
                    }
                }
                .boxchat_information_members_member_dropdown {
                    opacity: 0;
                    position: relative;
                    .dropdown_member {
                        position: absolute;
                        bottom: -230%;
                        left: -600%;
                        width: 150px;
                        height: 70px;
                        background-color: white;
                        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                        z-index: 99;
                        border-radius: 5px;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        justify-content: center;
                        .dropdown_member_outgroup {
                            padding: 5px 0;
                            text-align: left;
                            width: 100%;
                            font-size: 14px;
                            span {
                                margin-left: 10px;
                            }
                            &:hover {
                                background-color: #e6e9eb;
                            }
                        }
                        .dropdown_member_subcreator {
                            padding: 5px 0;
                            text-align: left;
                            width: 100%;
                            font-size: 14px;
                            span {
                                margin-left: 10px;
                            }
                            &:hover {
                                background-color: #e6e9eb;
                            }
                        }
                    }
                }
            }
        }
        .boxchat_information_members_list::-webkit-scrollbar {
            width: 10px;
        }
        .boxchat_information_members_list::-webkit-scrollbar-thumb {
            background-color: #b3babe;
            border-radius: 25px;
        }
    }
    .boxchat_information_image {
        width: 100%;
        border-bottom: 1px solid rgba($color: gray, $alpha: 0.5);
        .boxchat_information_image_top {
            width: 100%;
            height: 30px;
            display: flex;
            align-items: center;
            border-bottom: 1px solid rgba($color: gray, $alpha: 0.2);
            i {
                margin: 10px 10px 0px 10px;
            }
            p {
                margin: 7px 10px 0px 10px;
            }
        }
        .boxchat_information_image_bottom {
            width: 100%;
            height: auto;
            max-height: 250px;
            overflow: auto;
            display: flex;
            flex-wrap: wrap;
        }
        .boxchat_information_image_bottom::-webkit-scrollbar {
            display: none;
        }
    }
    .boxchat_information_file {
        width: 100%;
        border-bottom: 1px solid rgba($color: gray, $alpha: 0.5);
        .boxchat_information_file_top {
            width: 100%;
            height: 30px;
            display: flex;
            align-items: center;
            border-bottom: 1px solid rgba($color: gray, $alpha: 0.2);
            i {
                margin: 10px 10px 0px 10px;
            }
            p {
                margin: 7px 10px 0px 10px;
            }
        }
        .boxchat_information_file_bottom {
            width: 100%;
            height: auto;
            max-height: 250px;
            overflow: auto;
        }
        .boxchat_information_file_bottom::-webkit-scrollbar {
            display: none;
        }
    }

    .boxchat_information_edit {
        width: 100%;
    }
    .boxchat_information_out {
        width: 100%;
    }
}
.boxchat_information::-webkit-scrollbar {
    display: none;
}

.name_Informationboxchat {
    font-weight: bold;
    font-size: 25px;
    text-align: center;
    margin-bottom: 10px;
}

@media only screen and (max-width: 600px) {
    .right {
        width: 0% !important;
        display: none !important;
        position: relative;
        &.activeChat {
            display: block !important;
            width: 85% !important;
        }
    }
    .boxchat_information {
        width: 85% !important;
        position: absolute;
        top: 0;
        right: 0;
        display: none;
        visibility: hidden;
        &.openFormBoxChatInfo {
            position: absolute;
            top: 0;
            right: 0;
            display: block;
            visibility: visible;
            height: 100%;
            background-color: white;
        }
        .boxchat_information_title {
            i {
                display: block;
            }
        }
    }
}

@media only screen and (min-width: 600px) {
    .right {
        width: 0% !important;
        display: none !important;
        position: relative;
        &.activeChat {
            display: block !important;
            width: 90% !important;
        }
    }
    .boxchat_information {
        width: 50% !important;
        position: absolute;
        top: 0;
        right: 0;
        display: none;
        visibility: hidden;
        &.openFormBoxChatInfo {
            position: absolute;
            top: 0;
            right: 0;
            display: block;
            visibility: visible;
            height: 100%;
            background-color: white;
        }
        .boxchat_information_title {
            i {
                display: block;
            }
        }
    }
}

@media only screen and (min-width: 768px) {
    .right {
        width: 63% !important;
        display: block !important;
        &.activeChat {
            width: 63% !important;
            display: block !important;
        }
    }
    .boxchat_information {
        width: 40% !important;
        position: absolute;
        top: 0;
        right: 0;
        display: none;
        visibility: hidden;
        &.openFormBoxChatInfo {
            display: block;
            visibility: visible;
        }
        .boxchat_information_title {
            i {
                display: block;
            }
        }
    }
}

@media only screen and (min-width: 992px) {
    .right {
        width: 63% !important;
        display: block !important;
        &.activeChat {
            width: 63% !important;
            display: block !important;
        }
    }
    .boxchat_information {
        width: 30% !important;
        position: absolute;
        top: 0;
        right: 0;
        display: none;
        visibility: hidden;
        &.openFormBoxChatInfo {
            display: block;
            visibility: visible;
        }
        .boxchat_information_title {
            i {
                display: block;
            }
        }
    }
}

@media only screen and (min-width: 1200px) {
    .right {
        width: 70% !important;
        &.activeChat {
            width: 70% !important;
        }
        &.openFormBoxChatInfo {
            width: 48% !important;
        }
    }
    .boxchat_information {
        width: 0% !important;
        &.openFormBoxChatInfo {
            width: 22% !important;
        }
        .boxchat_information_title {
            i {
                display: block;
            }
        }
    }
}
