.document {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 5px 0;
    cursor: pointer;
    &:hover {
        background-color: rgba($color: gray, $alpha: 0.5);
    }
    .icon_document {
        margin: 0 5px;
        i {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 20px;
            height: 20px;
            padding: 5px;
            background-color: #9acd32;
            border-radius: 50%;
            color: white;
        }
    }
    .infor_document {
        width: 80%;
        text-align: left;
        .name_document {
            font-size: 14px;
            font-weight: bold;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .size_document {
            font-size: 12px;
            color: gray;
        }
    }
}
