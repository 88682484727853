* {
    margin: 0;
    padding: 0;
}

.body {
    width: 100%;
    height: 100vh;
    display: flex;
    position: relative;
    .left {
        width: 5%;
        height: 100%;
        background-color: #0091ff;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;

        .user {
            margin-top: 10px;
            width: 50px;
            height: 50px;
            cursor: pointer;
            img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
            }
        }
        .chat {
            margin-top: 10px;
            width: 100%;
            height: 60px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            i {
                color: white;
                font-size: 30px;
            }
            &:hover {
                background-color: #40adff;
            }
        }
        .phonebook {
            width: 100%;
            height: 60px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            i {
                color: white;
                font-size: 30px;
            }
            &:hover {
                background-color: #40adff;
            }
        }
        .setting {
            margin-top: 10px;
            width: 100%;
            height: 60px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            position: absolute;
            bottom: 0;
            z-index: 99;
            i {
                width: 100%;
                color: white;
                font-size: 30px;
            }
            &:hover {
                background-color: #40adff;
            }
            .setting_dropdown {
                position: absolute;
                bottom: 100%;
                right: -150%;
                width: 150px;
                height: 100px;
                background-color: white;
                border: 1px solid rgba($color: gray, $alpha: 0.5);
                box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                .setting_detail {
                    margin-top: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    padding: 10px 0;
                    i {
                        color: black;
                        font-size: 16px;
                        width: 15%;
                        margin-right: 15px;
                    }
                    &:hover {
                        background-color: #edf1f8;
                    }
                }
                .logout {
                    padding: 10px 0;
                    color: red;
                    font-size: 14px;
                    &:hover {
                        background-color: #edf1f8;
                    }
                }
            }
        }
    }
    .welcome {
        width: 70%;
        height: 100%;
    }
}
@media only screen and (max-width: 600px) {
    .left {
        width: 15% !important;
    }
    .welcome {
        display: none !important;
    }
}

@media only screen and (min-width: 600px) {
    .left {
        width: 10% !important;
    }
    .welcome {
        display: none !important;
    }
}

@media only screen and (min-width: 768px) {
    .left {
        width: 7% !important;
    }
    .welcome {
        width: 63% !important;
        display: block !important;
    }
}

@media only screen and (min-width: 992px) {
    .left {
        width: 7% !important;
    }
    .welcome {
        width: 63% !important;
        display: block !important;
    }
}

@media only screen and (min-width: 1200px) {
    .left {
        width: 5% !important;
    }
    .welcome {
        display: block !important;
    }
}

.active_toggle {
    background-color: #006edc;
    &:hover {
        background-color: #006edc !important;
    }
}
