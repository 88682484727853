.image {
    width: 31%;
    height: 90px;
    padding: 2px;
    cursor: pointer;
    position: relative;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(gray, 0.5);
        opacity: 0;
    }
    &:hover {
        &::after {
            opacity: 1;
        }
    }
}
