.list_message_system {
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    .message {
        width: auto;
        max-width: 75%;
        margin: 5px 10px 5px 7px;
        .message_text {
            font-weight: bold;
            font-size: 16px;
        }

        .message_system {
            font-weight: bold;
            font-size: 16px;
        }
    }
}
.list_message_owner {
    align-items: flex-end;
    text-align: left;
    .message {
        padding: 10px;
        background-color: #effdde;
        border-radius: 5px;
        position: relative;
        cursor: pointer;
        &:hover {
            .container_dropdown {
                opacity: 1;
                visibility: visible;
            }
        }

        .container_dropdown {
            position: absolute;
            bottom: 0;
            left: -190px;
            width: 170px;
            height: auto;
            padding: 10px;
            z-index: 999;
            opacity: 0;
            visibility: hidden;
            .dropdown_message {
                padding: 10px 0;
                width: 100%;
                height: 100%;
                background-color: white;
                border-radius: 5px;
                box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
                ul {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    li {
                        padding: 2px 10px;
                        list-style: none;
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        cursor: pointer;
                        &:hover {
                            background-color: #f1f1f1;
                        }
                        i {
                            width: 20px;
                            height: 20px;
                            color: gray;
                        }
                        p {
                            text-align: left;
                            margin-left: 20px;
                            color: black;
                        }
                    }
                }
            }
        }
        .message_text {
            width: 100%;
            font-weight: normal;
            word-wrap: break-word;
        }
        .message_photo {
            width: auto;
            height: auto;
            max-width: 300px;
            img {
                width: auto;
                height: auto;
                max-height: 300px;
            }
            .message_photo_text {
                width: auto;
                font-weight: normal;
                word-wrap: break-word;
            }
        }
        .message_file {
            width: auto;
            height: auto;
            .message_file_container {
                width: auto;
                height: auto;
                .message_file_container_top {
                    display: inline-flex;
                    .icon_file {
                        width: 20px;
                        height: 20px;
                        margin-right: 10px;
                        padding: 10px;
                        background-color: yellowgreen;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        i {
                            color: white;
                        }
                    }
                    .message_file_right {
                        .name_file {
                            font-size: 14px;
                            width: 100%;
                            font-weight: bold;
                        }
                        .size_file {
                            color: gray;
                            font-size: 12px;
                            display: flex;
                            justify-content: space-between;
                            text-align: right;
                            i {
                                margin-left: 10px;
                                cursor: pointer;
                                padding: 5px;
                                border: 1px solid rgba($color: gray, $alpha: 0.3);
                                border-radius: 5px;
                            }
                            .status_success {
                                width: 100%;
                                border-radius: 5px;
                                i {
                                    border: 1px solid rgba($color: gray, $alpha: 0.3);
                                    background-color: white;
                                    margin: 0;
                                }
                            }
                            .status_failed {
                                color: red;
                                width: 100%;
                                border-radius: 5px;
                                i {
                                    border: 1px solid rgba($color: gray, $alpha: 0.3);
                                    background-color: white;
                                    margin: 0;
                                }
                            }
                        }
                    }
                }
                .message_file_container_bottom {
                    width: auto;
                    .message_file_text {
                        word-wrap: break-word;
                    }
                }
            }
        }
        .message_delete {
            pointer-events: none;
            color: red;
            opacity: 0.4;
        }
        .message_contact {
            height: 100px;
            width: 200px;
            cursor: pointer;
            .contact_top {
                width: 100%;
                height: 70%;
                display: flex;
                border-bottom: 1px solid rgba($color: gray, $alpha: 0.3);
                .contact_avatar {
                    width: 40%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .contact_infor {
                    width: 60%;
                    height: 100%;
                    .contact_title {
                        color: gray;
                        font-size: 15px;
                    }
                    .contact_name {
                        margin-top: 10px;
                        width: 100%;
                        font-weight: bold;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }
            .contact_bottom {
                width: 100%;
                height: 30%;
                display: flex;
                align-items: center;
                justify-content: center;
                .chat {
                    color: blue;
                    font-weight: bold;
                    font-size: 14px;
                }
            }
        }
        .message_forward {
            .message_forward_icon {
                width: 100%;
                height: 10%;
                color: gray;
                font-size: 14px;
                margin-bottom: 10px;
            }
            .message_forward_content {
                width: 100%;
                height: auto;
            }
        }
        .message_time {
            text-align: right;
            margin-top: 5px;
            color: gray;
            font-size: 12px;
        }
        .message-reply-text {
            width: auto;
            height: auto;
            border-radius: 5px;
            background-color: #f1f1f1;
            padding: 10px;
            font-size: 14px;
            margin-bottom: 10px;
            .message-reply-sender {
                font-weight: 500;
            }
            .message-reply-content {
                width: 100%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
        .message-reply-photo {
            width: auto;
            height: auto;
            border-radius: 5px;
            background-color: #f1f1f1;
            padding: 10px;
            font-size: 14px;
            margin-bottom: 10px;
            .message-reply-photo-title {
                margin-bottom: 5px;
                color: red;
            }
            .message-reply-photo-content {
                display: flex;
                .message-reply-photo-left {
                    width: 50px;
                    height: 40px;
                    img {
                        object-fit: cover;
                        width: 100%;
                        height: 100%;
                    }
                }
                .message-reply-photo-right {
                    margin-left: 5px;
                    width: 90%;
                    height: 100%;
                    .message-reply-sender {
                        font-weight: 500;
                    }
                    .message-reply-content {
                        width: 100%;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }
        }
        .message-reply-file {
            width: auto;
            height: auto;
            border-radius: 5px;
            background-color: #f1f1f1;
            padding: 10px;
            font-size: 14px;
            margin-bottom: 10px;
            .message-reply-file-title {
                color: red;
                margin-bottom: 5px;
            }
            .message-reply-file-content {
                display: flex;
                .message-reply-file-left {
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: #9acd32;
                    padding: 10px;
                }
                .message-reply-file-right {
                    margin-left: 5px;
                    width: 90%;
                    height: 100%;
                    .message-reply-sender {
                        font-weight: 500;
                    }
                    .message-reply-content {
                        width: 100%;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }
        }
        .icon-pin {
            color: red;
        }
    }
}
.list_message_other {
    flex-direction: row;
    align-items: flex-start;
    text-align: left;
    .forward {
        color: gray;
        font-size: 14px;
        margin: 0 15px;
    }

    .message {
        padding: 10px;
        background-color: white;
        border-radius: 5px;
        position: relative;
        cursor: pointer;
        &:hover {
            .container_dropdown {
                opacity: 1;
                visibility: visible;
            }
        }
        .message-name-sender {
            width: auto;
            max-width: 80%;
            font-weight: 500;
            font-size: 14px;
        }
        .container_dropdown {
            position: absolute;
            bottom: 0;
            right: -190px;
            width: 170px;
            height: auto;
            padding: 10px;
            z-index: 999;
            opacity: 0;
            visibility: hidden;
            .dropdown_message {
                width: 100%;
                height: 100%;
                padding: 10px 0;
                background-color: white;
                border-radius: 5px;
                box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
                ul {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    li {
                        padding: 2px 10px;
                        list-style: none;
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        cursor: pointer;
                        &:hover {
                            background-color: #f1f1f1;
                        }
                        i {
                            width: 20px;
                            height: 20px;
                            color: gray;
                        }
                        p {
                            margin-left: 20px;
                            color: black;
                        }
                    }
                }
            }
        }
        .message_text {
            width: 100%;
            font-weight: normal;
            word-wrap: break-word;
        }
        .message_photo {
            width: auto;
            height: auto;
            max-width: 300px;
            img {
                width: auto;
                height: auto;
                max-height: 300px;
            }
            .message_photo_text {
                width: 100%;
                font-weight: normal;
                word-wrap: break-word;
            }
        }
        .message_file {
            width: auto;
            height: auto;
            .message_file_container {
                width: auto;
                height: auto;
                .message_file_container_top {
                    display: inline-flex;
                    .icon_file {
                        width: 20px;
                        height: 20px;
                        margin-right: 10px;
                        padding: 10px;
                        background-color: yellowgreen;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        i {
                            color: white;
                        }
                    }
                    .message_file_right {
                        .name_file {
                            font-size: 14px;
                            width: 100%;
                            font-weight: bold;
                        }
                        .size_file {
                            color: gray;
                            font-size: 12px;
                            display: flex;
                            justify-content: space-between;
                            text-align: right;
                            i {
                                margin-left: 10px;
                                cursor: pointer;
                                padding: 5px;
                                border: 1px solid rgba($color: gray, $alpha: 0.3);
                                border-radius: 5px;
                            }
                            .status_success {
                                width: 100%;
                                border-radius: 5px;
                                i {
                                    border: 1px solid rgba($color: gray, $alpha: 0.3);
                                    background-color: white;
                                    margin: 0;
                                }
                            }
                            .status_failed {
                                color: red;
                                width: 100%;
                                border-radius: 5px;
                                i {
                                    border: 1px solid rgba($color: gray, $alpha: 0.3);
                                    background-color: white;
                                    margin: 0;
                                }
                            }
                        }
                    }
                }
                .message_file_container_bottom {
                    width: auto;
                    .message_file_text {
                        word-wrap: break-word;
                    }
                }
            }
        }
        .message_delete {
            pointer-events: none;
            color: red;
            opacity: 0.4;
        }
        .message_forward {
            .message_forward_icon {
                width: 100%;
                height: 10%;
                color: gray;
                font-size: 14px;
                margin-bottom: 10px;
            }
            .message_forward_content {
                width: 100%;
                height: auto;
            }
        }
        .message_time {
            margin-top: 5px;
            color: gray;
            font-size: 12px;
        }
        .message-reply-text {
            width: auto;
            height: auto;
            border-radius: 5px;
            background-color: #f1f1f1;
            padding: 10px;
            font-size: 14px;
            margin-bottom: 10px;
            .message-reply-sender {
                font-weight: 500;
            }
            .message-reply-content {
                width: 100%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
        .message-reply-photo {
            width: auto;
            height: auto;
            border-radius: 5px;
            background-color: #f1f1f1;
            padding: 10px;
            font-size: 14px;
            margin-bottom: 10px;
            .message-reply-photo-title {
                margin-bottom: 5px;
                color: red;
            }
            .message-reply-photo-content {
                display: flex;
                .message-reply-photo-left {
                    width: 50px;
                    height: 40px;
                    img {
                        object-fit: cover;
                        width: 100%;
                        height: 100%;
                    }
                }
                .message-reply-photo-right {
                    margin-left: 5px;
                    width: 90%;
                    height: 100%;
                    .message-reply-sender {
                        font-weight: 500;
                    }
                    .message-reply-content {
                        width: 100%;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }
        }
        .message-reply-file {
            width: auto;
            height: auto;
            border-radius: 5px;
            background-color: #f1f1f1;
            padding: 10px;
            font-size: 14px;
            margin-bottom: 10px;
            .message-reply-file-title {
                color: red;
                margin-bottom: 5px;
            }
            .message-reply-file-content {
                display: flex;
                .message-reply-file-left {
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: #9acd32;
                    padding: 10px;
                }
                .message-reply-file-right {
                    margin-left: 5px;
                    width: 90%;
                    height: 100%;
                    .message-reply-sender {
                        font-weight: 500;
                    }
                    .message-reply-content {
                        width: 100%;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }
        }
        .icon-pin {
            text-align: right;
            color: red;
        }
    }
    .message-avatar-sender {
        height: 100%;
        width: 5%;
        display: flex;
    }
}

@media only screen and (max-width: 600px) {
    .list_message_other {
        .message-avatar-sender {
            width: 9%;
        }
    }
}
