.center {
    width: 25%;
    height: 100%;
    border-right: 1px solid rgba($color: gray, $alpha: 0.5);
    overflow-x: hidden;
    .title_center_listRoom {
        width: 100%;
        height: 10%;
        font-size: 18px;
        font-weight: bold;
        display: flex;
        border-bottom: 1px solid rgba($color: gray, $alpha: 0.5);
        .title {
            width: 80%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .title_center_listRoom_icon {
            width: 20%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            i {
                width: 10%;
                padding: 7px 10px;
                margin-right: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 5px;
                cursor: pointer;
                &:hover {
                    background-color: #caced4;
                }
            }
        }
    }
    .listRoom {
        width: 100%;
        height: 89%;
        overflow-y: auto;
        overflow-x: hidden;
        .room {
            width: 99%;
            padding: 10px 5px;
            display: flex;
            align-items: center;
            cursor: pointer;
            &:hover {
                background-color: #dee1e4;
            }
            .active_toggle {
                background-color: #a7d4f0;
                &:hover {
                    background-color: #a7d4f0;
                }
            }
            .room_avatar {
                width: 15%;
                height: 100%;
                margin-right: 10px;
                i {
                    color: white;
                    font-size: 20px;
                    padding: 10px;
                    border-radius: 50%;
                }
            }
            .room_infor {
                width: 80%;
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                .room_name {
                    width: 100%;
                    display: flex;
                    .name {
                        width: 80%;
                        display: flex;
                        align-items: center;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        i {
                            width: 10%;
                            margin-right: 5px;
                        }
                        p {
                            width: 90%;
                            text-align: left;
                            font-weight: bold;
                            display: flex;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }
                    .time {
                        text-align: right;
                        width: 20%;
                        color: gray;
                        font-weight: normal;
                        font-size: 14px;
                    }
                }
                .lastMessage {
                    text-align: left;
                    width: 100%;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                .lastMessage_text {
                    width: 100%;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                .lastMessage_file {
                    i {
                        margin-right: 5px;
                    }
                }
            }
        }
    }
    .listRoom::-webkit-scrollbar {
        display: none;
    }
}
@media only screen and (max-width: 600px) {
    .center {
        width: 85% !important;
        display: block;
        &.activeChat {
            width: 0% !important;
            display: none;
        }
    }
}

@media only screen and (min-width: 600px) {
    .center {
        width: 90% !important;
        display: block;
        &.activeChat {
            width: 0%;
            display: none;
        }
    }
}

@media only screen and (min-width: 768px) {
    .center {
        width: 30% !important;
        display: block !important;
        &.activeChat {
            width: 30% !important;
            display: block !important;
        }
    }
}

@media only screen and (min-width: 992px) {
    .center {
        width: 30% !important;
        display: block !important;
        &.activeChat {
            width: 30% !important;
            display: block !important;
        }
    }
}

@media only screen and (min-width: 1200px) {
    .center {
        width: 25% !important;
        display: block !important;
        &.activeChat {
            width: 25% !important;
            display: block !important;
        }
    }
}
