.center {
    width: 25%;
    height: 100%;
    border-right: 1px solid rgba($color: gray, $alpha: 0.5);
    .center_top {
        width: 100%;
        height: 10%;
        border-bottom: 1px solid rgba($color: gray, $alpha: 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        .search {
            margin-left: 10px;
            width: 70%;
            display: flex;
            align-items: center;
            i {
                display: flex;
                align-items: center;
                height: 30px;
                background-color: #e8eaef;
                border-bottom-left-radius: 10px;
                border-top-left-radius: 10px;
                padding-left: 10px;
            }
            input {
                width: 100%;
                height: 30px;
                padding-left: 5px;
                border: none;
                outline: none;
                background-color: #e8eaef;
                border-bottom-right-radius: 10px;
                border-top-right-radius: 10px;
            }
        }
        .icon {
            width: 30%;
            display: flex;
            align-items: center;
            justify-content: center;
            i {
                width: 10%;
                padding: 7px 8px;
                margin-right: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 5px;
                cursor: pointer;
                &:hover {
                    background-color: #caced4;
                }
            }
        }
    }
    .group-chat {
        width: 100%;
        height: 10%;
        border-bottom: 1px solid rgba($color: gray, $alpha: 0.5);
        display: flex;
        align-items: center;
        cursor: pointer;
        &:hover {
            background-color: #caced4;
        }
        .group-chat-icon {
            width: 50px;
            height: 50px;
            background-color: #5243aa;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 10px;
            i {
                color: white;
                font-size: 20px;
            }
        }
        .group-chat-title {
            margin-left: 10px;
            font-weight: bold;
            font-size: 18px;
        }
    }
    .list_friend {
        width: 100%;
        height: 79%;
        overflow-y: auto;
        .list_friend_title {
            display: flex;
            margin: 10px 0 0 10px;
            font-weight: bold;
        }
        .friend {
            padding: 10px;
            display: flex;
            align-items: center;
            cursor: pointer;
            &:hover {
                background-color: #dee1e4;
                .friend_btn_delete {
                    opacity: 1;
                }
            }
            .friend-container {
                width: 90%;
                height: 100%;
                display: flex;
                .friend_avatar {
                    height: 100%;
                    margin-right: 10px;
                }
                .friend_infor {
                    width: 70%;
                    height: 100%;
                    .friend_name {
                        display: flex;
                        font-weight: bold;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                    .friend_status {
                        font-size: 14px;
                        display: flex;
                        font-weight: bold;
                        color: green;
                    }
                    .friend_status_offline {
                        color: gray;
                    }
                }
            }

            .friend_btn_delete {
                height: 100%;
                width: 10%;
                opacity: 0;
                margin-left: 10px;
                i {
                    color: red;
                }
            }
        }
    }
    .list_friend::-webkit-scrollbar {
        display: none;
    }
}

@media only screen and (max-width: 600px) {
    .center {
        width: 85%;
        display: block;
        &.activeChat {
            width: 0%;
            display: none;
        }
    }
}

@media only screen and (min-width: 600px) {
    .center {
        width: 90%;
        display: block;
        &.activeChat {
            width: 0%;
            display: none;
        }
    }
}

@media only screen and (min-width: 768px) {
    .center {
        width: 30%;
        display: block;
        &.activeChat {
            width: 30%;
            display: block;
        }
    }
}

@media only screen and (min-width: 992px) {
    .center {
        width: 30%;
        display: block;
        &.activeChat {
            width: 30%;
            display: block;
        }
    }
}

@media only screen and (min-width: 1200px) {
    .center {
        width: 25%;
        display: block;
        &.activeChat {
            width: 25%;
            display: block;
        }
    }
}
