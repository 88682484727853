.listChatGroup {
    height: 100%;
    width: 70%;
    .listChatGroup-header {
        width: 100%;
        height: 10%;
        display: flex;
        align-items: center;
        border-bottom: 1px solid rgba($color: gray, $alpha: 0.5);
        .listChatGroup-icon {
            margin-left: 15px;
            i {
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 23px;
                width: 50px;
                height: 50px;
                border-radius: 50%;
                color: white;
                background-color: #5243aa;
            }
        }
        .listChatGroup-title {
            margin-left: 15px;
            font-weight: bold;
            font-size: 20px;
        }
    }
    .listChatGroup-list {
        width: 100%;
        height: 89%;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        overflow-y: auto;
        background-color: #fafafc;
        .listChatGroup-group {
            width: 25%;
            height: 200px;
            border: 1px solid rgba($color: gray, $alpha: 0.5);
            margin: 20px 0 0 40px;
            background-color: white;
            border-radius: 5px;
            padding: 10px;
            cursor: pointer;
            .listChatGroup-group-avatar {
                width: 100%;
                height: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .listChatGroup-group-name {
                width: 100%;
                height: 20%;
                font-weight: bold;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .listChatGroup-group-member {
                width: 100%;
                height: 30%;
                color: rgba($color: gray, $alpha: 0.7);
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .listChatGroup {
        display: none;
        width: 0%;
        &.isActive {
            width: 85%;
            display: block;
        }
        .listChatGroup-list {
            justify-content: space-between;
            .listChatGroup-group {
                width: 70%;
                margin: 20px 40px 0 40px;
            }
        }
    }
}

@media only screen and (min-width: 600px) {
    .listChatGroup {
        display: none;
        width: 0%;
        &.isActive {
            width: 90%;
            display: block;
        }
        .listChatGroup-list {
            justify-content: space-between;
            .listChatGroup-group {
                width: 30%;
                margin: 20px 40px 0 40px;
            }
        }
    }
}

@media only screen and (min-width: 768px) {
    .listChatGroup {
        display: block;
        width: 63%;
        &.isActive {
            display: block;
            width: 63%;
        }
        .listChatGroup-list {
            justify-content: space-around;
            .listChatGroup-group {
                width: 25%;
                margin: 20px 10px 0 10px;
            }
        }
    }
}

@media only screen and (min-width: 992px) {
    .listChatGroup {
        display: block;
        width: 63%;
        &.isActive {
            display: block;
            width: 63%;
        }
        .listChatGroup-list {
            justify-content: space-between;
            .listChatGroup-group {
                width: 25%;
                margin: 20px 15px 0 15px;
            }
        }
    }
}

@media only screen and (min-width: 1200px) {
    .listChatGroup {
        display: block;
        width: 70%;
        &.isActive {
            display: block;
            width: 70%;
        }
        .listChatGroup-list {
            justify-content: space-between;
            .listChatGroup-group {
                width: 25%;
                margin: 20px 20px 0 20px;
            }
        }
    }
}
