.update-contact {
    width: 300px;
    height: 130px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .update-contact-avatar {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 15px;
    }
    .update-contact-edit {
        width: 100%;
        margin-bottom: 15px;
    }
}
