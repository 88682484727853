.result {
    width: 90%;
    height: 300px;
    margin: 0 auto;
    overflow-y: auto;
    .user {
        margin-top: 15px;
        width: 100%;
        display: flex;
        cursor: pointer;
        &:hover {
            background-color: rgba($color: gray, $alpha: 0.5);
        }
        .user_avatar {
            width: 20%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .user_infor {
            width: 80%;
            height: 50px;
            display: flex;
            align-items: center;
            .user_infor_name {
                font-weight: bold;
            }
        }
    }
}

.user_add {
    width: 100%;
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .user_add_name {
        width: 90%;
        text-align: center;
        margin-top: 10px;
        font-weight: bold;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
