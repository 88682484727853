.contacts {
    width: 20%;
    height: auto;
    margin-top: 10px;
    border-radius: 5px;
    overflow-y: auto;
    overflow-x: hidden;
    .contact {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 5px;
        border-radius: 5px;
        cursor: pointer;
        &:hover {
            background-color: rgba($color: gray, $alpha: 0.5);
        }
        .contact_checkIcon {
            height: 20px;
            width: 20px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
            color: white;
        }
        .contact_checkIcon_active {
            background-color: blue;
        }
        .contact_avatar {
            height: 100%;
            margin-right: 10px;
        }
        .contact_name {
            height: 100%;
            width: 70%;
            font-weight: bold;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    .contact_added {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 5px;
        border-radius: 5px;
        background-color: rgba($color: gray, $alpha: 0.5);
        cursor: pointer;
        &:hover {
            background-color: rgba($color: gray, $alpha: 0.5);
        }
        .contact_checkIcon {
            height: 20px;
            width: 20px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
            color: white;
            background-color: blue;
        }
        .contact_checkIcon_active {
            background-color: blue;
        }
        .contact_avatar {
            height: 100%;
            margin-right: 10px;
        }
        .contact_name {
            height: 100%;
            width: 70%;
            font-weight: bold;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}
